import React, { useContext, useEffect, useState } from 'react'
import { PATH_URL } from 'src/helpers'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from '../contextAPI'

export const QuizProgressBar = () => {
  const { screen, quizProgressPercent, setQuizProgressPercent } = useContext(QuizQuestionContext)
  const [percentage, setPercentage] = useState(quizProgressPercent)

  const setPercentageForScreen = (screen: SCREEN_QUIZ_QUESTION | undefined) => {
    switch (screen) {
      case SCREEN_QUIZ_QUESTION.NEW_COLOR_PALETTE_PAGE:
        setPercentage(15)
        setQuizProgressPercent(15)
        break
      case SCREEN_QUIZ_QUESTION.NEW_AVAILABILITY_PAGE:
        setPercentage(30)
        setQuizProgressPercent(30)
        break
      case SCREEN_QUIZ_QUESTION.YOUR_DETAILS:
        setPercentage(45)
        setQuizProgressPercent(45)
        break
      case SCREEN_QUIZ_QUESTION.PLANNING_STAGE:
        setPercentage(60)
        setQuizProgressPercent(60)
        break
      case SCREEN_QUIZ_QUESTION.GUEST_COUNT:
        setPercentage(75)
        setQuizProgressPercent(75)
        break
      case SCREEN_QUIZ_QUESTION.NEW_BUDGET_PAGE:
        setPercentage(90)
        setQuizProgressPercent(90)
        break
      case SCREEN_QUIZ_QUESTION.ESSENTIALS_COLLECTION_PAGE:
        setPercentage(90)
        setQuizProgressPercent(90)
        break
      case SCREEN_QUIZ_QUESTION.COUPLE_INFO:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  const setPercentageForPath = (pathname: string) => {
    switch (pathname) {
      case PATH_URL.NEW_COLOR_PALETTE_PAGE:
        setPercentage(15)
        setQuizProgressPercent(15)
        break
      case PATH_URL.NEW_AVAILABILITY_PAGE:
        setPercentage(30)
        setQuizProgressPercent(30)
        break
      case PATH_URL.YOUR_DETAILS:
        setPercentage(45)
        setQuizProgressPercent(45)
        break
      case PATH_URL.PLANNING_STAGE:
        setPercentage(60)
        setQuizProgressPercent(60)
        break
      case PATH_URL.GUEST_COUNT:
        setPercentage(75)
        setQuizProgressPercent(75)
        break
      case PATH_URL.BUDGET_PAGE:
        setPercentage(90)
        setQuizProgressPercent(90)
        break
      case PATH_URL.ESSENTIALS_COLLECTION_PAGE:
        setPercentage(90)
        setQuizProgressPercent(90)
        break
      case PATH_URL.CONTACT_INFO:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
      default:
        setPercentage(100)
        setQuizProgressPercent(100)
        break
    }
  }

  useEffect(() => {
    setPercentageForScreen(screen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  useEffect(() => {
    setPercentageForPath(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <div className={'quiz-progress-bar'}>
        <div className="progress-percentage" style={{ width: `${percentage}%` }}></div>
      </div>
    </>
  )
}
