/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useEssentialsCollection } from './EssentialsCollection.hook'
import { QuizQuestionWrapper } from '../../QuizQuestionWrapper'
import { Helmet } from 'react-helmet'
import essentialCover from 'src/assets/images/essentials-cover.jpg'
import './EssentialsCollection.scss'

export const EssentialsCollection = () => {
  const { handleNext, handleBack, handleGoToEssentialsCollection } = useEssentialsCollection()

  return (
    <>
      <Helmet>
        <script>
          {`
            function gtag_report_conversion(params){
              gtag('event', 'conversion', params);
              return false;
            }
          `}
        </script>
      </Helmet>
      <QuizQuestionWrapper
        titleStep="YOUR ESSENTIALS COLLECTION"
        nextAction={{
          disabled: true,
        }}
        backAction={{
          onAction: handleBack,
        }}
        step={6}
        total={7}
        customCss={{
          title: 'title-bold',
          content: 'essentials-collection-wrapper',
        }}
      >
        <div className="ec-section">
          <p className="ec-label">SUGGESTED FOR YOU</p>
          <div className="ec-two-columns">
            <div>
              <div className="ec-content">
                <h1 className="ec-title">The Essentials Collection</h1>
                <p className="ec-minimum-budget">From $1,750</p>
                <p className="ec-description">
                  Stylish, ready-made floral designs, perfect for couples seeking beautiful flowers at an affordable
                  price
                </p>
                <ul className="ec-checklist">
                  <li>Choose from 5 curated color palettes</li>
                  <li>Choose from 2 timeless styles</li>
                  <li>1 complimentary call with a trusted Design Specialist</li>
                  <li>Designed & arranged by expert Poppy Designers</li>
                  <li>Delivery & set up at your venue</li>
                </ul>
              </div>
              <div className="action-box-wrapper">
                <button className="button white-space-wrap" onClick={handleGoToEssentialsCollection}>
                  SHOP THE COLLECTION
                </button>
              </div>
            </div>
            <div>
              <img src={essentialCover} alt="Essentials Collection" className="ec-cover" />
            </div>
          </div>
          <div className="ec-footer">
            <div className="ec-button-link" onClick={handleNext}>
              Continue to Signature Collection
            </div>
          </div>
        </div>
      </QuizQuestionWrapper>
    </>
  )
}
