/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { QuizQuestionContext, SCREEN_QUIZ_QUESTION } from 'src/pages/quiz/contextAPI'
import { PATH_URL } from 'src/helpers'
import { ENV } from 'src/config/env'

export const useEssentialsCollection = () => {
  const history = useHistory()
  const { setQuizScreen } = useContext(QuizQuestionContext)

  const handleNext = async () => {
    const currentTempPoppyData = JSON.parse(localStorage.getItem('tempPoppySessionData') || '{}')
    localStorage.setItem(
      'tempPoppySessionData',
      JSON.stringify({
        ...currentTempPoppyData,
      })
    )
    history.push({ pathname: PATH_URL.CONTACT_INFO })
    setQuizScreen(SCREEN_QUIZ_QUESTION.CONTACT_INFO)
  }

  const handleBack = () => {
    if (window.history.length > 1) {
      history.goBack()
    } else {
      history.push({ pathname: PATH_URL.NEW_BUDGET_PAGE })
      setQuizScreen(SCREEN_QUIZ_QUESTION.NEW_BUDGET_PAGE)
    }
  }

  const handleGoToEssentialsCollection = () => {
    window.open(ENV.ESSENTIALS_COLLECTION_URL, '_blank')
  }

  return {
    handleBack,
    handleNext,
    handleGoToEssentialsCollection,
  }
}
