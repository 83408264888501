import React, { useContext, useEffect, useState } from 'react'
import { getHttp } from 'src/api'
import { ImageCachePreloader } from 'src/common'
import { PATH_API, bindLocationToPlace } from 'src/helpers'
import { useAppContext } from 'src/helpers/app'
import { SESSION_STATE } from 'src/types'

import {
  Anything,
  Budget,
  ColorsFlowers,
  FlowersWedding,
  Unserviceable,
  Rituals,
  SeeItemPrices,
  GuestsAndTableExpect,
  RequestAProposal,
  WeddingDate,
} from './components'
import { BouquetShape } from './components/BouquetShape'
import { EndQuiz } from './components/EndQuiz'
import { FloralFocus } from './components/FloralFocus'
import { MetBefore } from './components/MetBefore/MetBefore'

import { QuizQuestionContext, QuizQuestionData, SCREEN_QUIZ_QUESTION } from './contextAPI'
import { IQuizData } from './contextAPI/quizQuestionContext'
import { EVENT_LOCATION_TYPE } from '../../types/question'
import { GuineaPig } from './components/GuineaPig/GuineaPig'

// import { Welcome } from './cannyValley/Welcome'
import { CoupleInfo } from './cannyValley/CoupleInfo'
import { ChoosePalette } from './cannyValley/ChoosePalette'
import { CustomPalette } from './cannyValley/CustomPalette'
import { Inspiration } from './cannyValley/Inspiration'
import { FlowersInterstitial } from './cannyValley/FlowersInterstitial'
import { CannyValleyBudget } from './cannyValley/Budget'
import { SampleFlower } from './cannyValley/SampleFlower'
import { useExternalQuiz } from './useExternalQuiz'
import { PersonalDetailsComponent } from './components/PersonalDetailsComponent'
import { YourDetails } from './components/NewFlow/YourDetailsPage/YourDetails'
import { GuestCount } from './components/NewFlow/GuestCountPage/GuestCount'
import { NewPlanningStage } from './components/NewFlow/PlanningStagePage/NewPlanningStage'
import { ACTION_APP, ga4EventApp, gaEventApp } from 'src/helpers/googleAnalyticsService'
import { SegmentAction, segmentTracking } from 'src/helpers/segment'
import { MAX_TIME_TRACKING, funcNoCallback } from './cannyValley/Budget/useBudget'
import { WeddingDetailsComponent } from './components/WeddingDetailsComponent'
import { MetBeforeAfterSubmitted } from './components/MetBeforeAfterSubmitted/MetBeforeAfterSubmitted'
import { Availability } from './components/NewFlow/AvailabilityPage/Availability'
// import { ColorPaletteComponent } from './components/NewFlow/ColorPalettePage/ColorPaletteComponent'
import { ColorPaletteComponentV2 } from './components/NewFlow/ColorPalettePage/ColorPaletteComponentV2'
import { NewBudget } from './components/NewFlow/BudgetPage/NewBudget'
import { ContactInfo } from './components/NewFlow/ContactInfo/ContactInfo'
import { EssentialsCollection } from './components/NewFlow/EssentialsCollection/EssentialsCollection'

const quizQuestionScreens: {
  [label: string]: React.ReactNode
} = {
  [SCREEN_QUIZ_QUESTION.YOU_BOOKED]: <RequestAProposal />,
  [SCREEN_QUIZ_QUESTION.ANYTHING]: <Anything />,
  [SCREEN_QUIZ_QUESTION.COLORS_FLOWERS]: <ColorsFlowers />,
  [SCREEN_QUIZ_QUESTION.FLOWERS_WEDDING]: <FlowersWedding />,
  [SCREEN_QUIZ_QUESTION.GUESTS_AND_TABLE_EXPECT]: <GuestsAndTableExpect />,
  [SCREEN_QUIZ_QUESTION.SEE_ITEM_PRICE]: <SeeItemPrices />,
  [SCREEN_QUIZ_QUESTION.RITUAL]: <Rituals />,
  [SCREEN_QUIZ_QUESTION.BUDGET]: <Budget />,
  [SCREEN_QUIZ_QUESTION.WEDDING_DATE]: <WeddingDate />,
  [SCREEN_QUIZ_QUESTION.BOUQUET_SHAPE]: <BouquetShape />,
  [SCREEN_QUIZ_QUESTION.FLORAL_FOCUS]: <FloralFocus />,
  [SCREEN_QUIZ_QUESTION.MET_BEFORE]: <MetBefore />,
  [SCREEN_QUIZ_QUESTION.END_QUIZ]: <EndQuiz />,
  [SCREEN_QUIZ_QUESTION.AFTER_QUIT_SUBMIT]: <GuineaPig />,
  // [SCREEN_QUIZ_QUESTION.WELCOME]: <Welcome />,
  [SCREEN_QUIZ_QUESTION.COUPLE_INFO]: <CoupleInfo />,
  [SCREEN_QUIZ_QUESTION.CHOOSE_PALETTE]: <ChoosePalette />,
  [SCREEN_QUIZ_QUESTION.CUSTOM_PALETTE]: <CustomPalette />,
  [SCREEN_QUIZ_QUESTION.INSPIRATION]: <Inspiration />,
  [SCREEN_QUIZ_QUESTION.FLOWERS_INTERSTITIAL]: <FlowersInterstitial />,
  [SCREEN_QUIZ_QUESTION.CANNY_VALLEY_BUDGET]: <CannyValleyBudget />,
  [SCREEN_QUIZ_QUESTION.SAMPLE_FLOWER]: <SampleFlower />,
  [SCREEN_QUIZ_QUESTION.PERSONAL_DETAILS]: <PersonalDetailsComponent />,
  [SCREEN_QUIZ_QUESTION.WEDDING_DETAILS]: <WeddingDetailsComponent />,
  [SCREEN_QUIZ_QUESTION.MET_BEFORE_AFTER_SUBMITTED]: <MetBeforeAfterSubmitted />,
  // New flow
  [SCREEN_QUIZ_QUESTION.NEW_COLOR_PALETTE_PAGE]: <ColorPaletteComponentV2 />,
  [SCREEN_QUIZ_QUESTION.NEW_AVAILABILITY_PAGE]: <Availability />,
  [SCREEN_QUIZ_QUESTION.YOUR_DETAILS]: <YourDetails />,
  [SCREEN_QUIZ_QUESTION.PLANNING_STAGE]: <NewPlanningStage />,
  [SCREEN_QUIZ_QUESTION.GUEST_COUNT]: <GuestCount />,
  [SCREEN_QUIZ_QUESTION.NEW_BUDGET_PAGE]: <NewBudget />,
  [SCREEN_QUIZ_QUESTION.ESSENTIALS_COLLECTION_PAGE]: <EssentialsCollection />,
  [SCREEN_QUIZ_QUESTION.CONTACT_INFO]: <ContactInfo />,
}

interface IQuizQuestionBaseProps {
  hardScreen?: SCREEN_QUIZ_QUESTION
}

export const QuizQuestionBase = ({ hardScreen }: IQuizQuestionBaseProps) => {
  const {
    screen,
    recordResponse,
    setQuizQuestionData,
    setQuizScreen,
    setData,
    data,
    setRecordResponse,
    quizQuestionData,
  } = useContext(QuizQuestionContext)
  const { quizSession, quizExperience, sessionID } = useAppContext()
  const [imagesPreload, setImagesPreload] = useState<string[]>([])
  useExternalQuiz()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ga4Event = (action: ACTION_APP, params: any = {}) =>
    new Promise((rs) => {
      ga4EventApp(action, { ...params, event_callback: rs })
      setTimeout(rs, MAX_TIME_TRACKING)
    })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _segmentTracking = (action: string, properties?: any) =>
    new Promise((rs) => {
      segmentTracking(action, properties ?? {}, {}, rs)
      setTimeout(rs, MAX_TIME_TRACKING)
    })

  useEffect(() => {
    if (quizSession) {
      const newData: IQuizData = {}
      newData.weddingDate = {
        weddingDate: quizSession?.event?.date,
        fullName: `${quizSession?.customer?.firstName || ''} ${quizSession?.customer?.lastName || ''}`,
        firstName: quizSession?.customer?.firstName,
        lastName: quizSession?.customer?.lastName,
        partnerName: quizSession?.customer?.partnerName,
        emailAddress: quizSession?.customer?.emailAddress,
        phoneNumber: quizSession?.customer?.phoneNumber,
      }
      newData.planningStage = {
        vendors: data.planningStage?.vendors ?? {},
        stage: quizSession.event?.planningStage,
        intent: data.planningStage?.intent ?? '',
      }
      const ceremonyLocation = quizSession.locations?.find((loc) => loc.type === EVENT_LOCATION_TYPE.CEREMONY)
      const tempLocation = {
        type: (ceremonyLocation?.type as EVENT_LOCATION_TYPE) || EVENT_LOCATION_TYPE.CEREMONY,
        place: '',
        venue: ceremonyLocation?.name || '',
        city: ceremonyLocation?.city || '',
        state: ceremonyLocation?.state || '',
        streetAddress: ceremonyLocation?.streetAddress || '',
        zipCode: ceremonyLocation?.zipCode || '',
        placeId: ceremonyLocation?.venue?.ggPlaceId || '',
        country: ceremonyLocation?.country || '',
      }
      newData.location = [
        {
          ...tempLocation,
          place: bindLocationToPlace(tempLocation) || '',
        },
      ]
      newData.budget = quizSession.event?.budget || 3000
      newData.colorPalette = quizSession.colorPalette
      newData.colorPreference = quizSession.customer?.colorPreference
      newData.inspirationPhotos = quizSession.inspirations
      newData.stemPreferences = quizSession.stemPreferences
      newData.referralSource = quizSession.event?.referralSource

      if (quizSession.proposal?.hadProposal) {
        setRecordResponse({ proposal: quizSession.proposal })
        setQuizScreen(SCREEN_QUIZ_QUESTION.MET_BEFORE)
      } else {
        switch (quizSession.sessionState) {
          case SESSION_STATE.CONTACT_INFO_ANSWERED:
            if (quizExperience === 'LEGACY') setQuizScreen(SCREEN_QUIZ_QUESTION.YOU_BOOKED)
            if (quizExperience === 'CANNY_VALLEY') setQuizScreen(SCREEN_QUIZ_QUESTION.CHOOSE_PALETTE)
            setRecordResponse({
              venueName: quizSession.venue?.venueName,
              imageUrl: quizSession.venue?.imageUrl,
              totalWeddings: quizSession.venue?.totalWeddings,
            })
            break
          case SESSION_STATE.BUDGET_ANSWERED:
            if (quizExperience === 'LEGACY') setQuizScreen(SCREEN_QUIZ_QUESTION.COLORS_FLOWERS)
            break
          case SESSION_STATE.COLOR_PALETTE_ANSWERED:
            if (quizExperience === 'CANNY_VALLEY') setQuizScreen(SCREEN_QUIZ_QUESTION.CUSTOM_PALETTE)
            break
          case SESSION_STATE.CUSTOM_PALETTE_ANSWERED:
            if (quizExperience === 'CANNY_VALLEY') setQuizScreen(SCREEN_QUIZ_QUESTION.INSPIRATION)
            break
          case SESSION_STATE.INSPIRATION_PHOTO_ANSWERED:
            if (quizExperience === 'CANNY_VALLEY') setQuizScreen(SCREEN_QUIZ_QUESTION.FLOWERS_INTERSTITIAL)
            break
          case SESSION_STATE.REVIEWED_SAMPLE_FLOWER:
            if (quizExperience === 'CANNY_VALLEY') setQuizScreen(SCREEN_QUIZ_QUESTION.CANNY_VALLEY_BUDGET)
            break
          default:
            break
        }
      }
      setData(newData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizSession])

  useEffect(() => {
    const initQuiz = async () => {
      try {
        const response = await getHttp(PATH_API.getQuizSessionData)
        const quizQuestionData: QuizQuestionData = response.data
        setQuizQuestionData(quizQuestionData)
      } catch (error) {}
    }
    initQuiz()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (quizQuestionData && sessionID) {
      if (quizExperience === 'LEGACY') {
        const allImages = quizQuestionData.colorPalettes?.flatMap((i) => {
          return i.moodboardImages?.map((i) => i.imageUrl)
        })
        if (allImages?.length) {
          setImagesPreload(allImages)
        }
      }
      if (quizExperience === 'CANNY_VALLEY') {
        let allImages: string[] = []
        allImages = allImages.concat(
          quizQuestionData.colorPalettes?.flatMap((i) => {
            return i.orbConfig?.imageUrls
          }) || []
        )
        allImages = allImages.concat(
          quizQuestionData.imagePreferenceOptions?.filter((i) => i.colorPaletteId)?.map((i) => i.imageUrl) || []
        )
        allImages = allImages.concat(quizQuestionData.budgetContent?.flatMap((i) => i.images) || [])
        if (allImages?.length) {
          setImagesPreload(allImages)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizQuestionData, sessionID])

  useEffect(() => {
    const handleSwitchPath = async (screen: SCREEN_QUIZ_QUESTION | undefined) => {
      switch (screen) {
        case SCREEN_QUIZ_QUESTION.BUDGET:
          const trackingBudget = async () => {
            const promiseFunc = [
              ga4Event(ACTION_APP.BUDGET_LOADED, undefined),
              _segmentTracking(SegmentAction.SQ_BUDGET_LOADED, undefined),
              funcNoCallback(() => {
                gaEventApp(ACTION_APP.BUDGET_LOADED, undefined)
              }),
            ]
            await Promise.all(promiseFunc)
          }
          await trackingBudget()

          break
        case SCREEN_QUIZ_QUESTION.COLORS_FLOWERS:
          const trackingColorPalette = async () => {
            const promiseFunc = [
              ga4Event(ACTION_APP.COLOR_PALETTE_LOADED, undefined),
              _segmentTracking(SegmentAction.SQ_COLOR_PALETTE_LOADED, undefined),
              funcNoCallback(() => {
                gaEventApp(ACTION_APP.COLOR_PALETTE_LOADED, undefined)
              }),
            ]
            await Promise.all(promiseFunc)
          }
          await trackingColorPalette()

          break
        case SCREEN_QUIZ_QUESTION.YOU_BOOKED:
          const trackingAvailability = async () => {
            const promiseFunc = [
              ga4Event(ACTION_APP.AVAILABILITY_LOADED, undefined),
              _segmentTracking(SegmentAction.SQ_AVAILABILITY_LOADED, undefined),
              funcNoCallback(() => {
                gaEventApp(ACTION_APP.AVAILABILITY_LOADED, undefined)
              }),
            ]
            await Promise.all(promiseFunc)
          }
          await trackingAvailability()
          break
        case SCREEN_QUIZ_QUESTION.WEDDING_DATE:
          if (recordResponse?.serviceable === false) {
            const trackingUnAvailability = async () => {
              const promiseFunc = [
                ga4Event(ACTION_APP.UNAVAILABILITY_LOADED),
                _segmentTracking(SegmentAction.SQ_UNAVAILABILITY_LOADED),
                funcNoCallback(() => {
                  gaEventApp(ACTION_APP.UNAVAILABILITY_LOADED)
                }),
              ]
              await Promise.all(promiseFunc)
            }
            await trackingUnAvailability()
          } else {
            const trackingWeddingDate = async () => {
              const promiseFunc = [
                ga4Event(ACTION_APP.DETAIL_LOADED, undefined),
                _segmentTracking(SegmentAction.SQ_DETAIL_LOADED, undefined),
                funcNoCallback(() => {
                  gaEventApp(ACTION_APP.DETAIL_LOADED, undefined)
                }),
              ]
              await Promise.all(promiseFunc)
            }
            await trackingWeddingDate()
          }
          break
        case SCREEN_QUIZ_QUESTION.WEDDING_DETAILS:
          if (recordResponse?.serviceable === false) {
            const trackingUnAvailability = async () => {
              const promiseFunc = [
                ga4Event(ACTION_APP.UNAVAILABILITY_LOADED),
                _segmentTracking(SegmentAction.SQ_UNAVAILABILITY_LOADED),
                funcNoCallback(() => {
                  gaEventApp(ACTION_APP.UNAVAILABILITY_LOADED)
                }),
              ]
              await Promise.all(promiseFunc)
            }
            await trackingUnAvailability()
          } else {
            const trackingWeddingDetails = async () => {
              const promiseFunc = [
                ga4Event(ACTION_APP.WEDDING_DETAIL_LOADED, undefined),
                _segmentTracking(SegmentAction.SQ_WEDDING_DETAIL_LOADED, undefined),
                funcNoCallback(() => {
                  gaEventApp(ACTION_APP.WEDDING_DETAIL_LOADED, undefined)
                }),
              ]
              await Promise.all(promiseFunc)
            }
            await trackingWeddingDetails()
          }
          break
        case SCREEN_QUIZ_QUESTION.PERSONAL_DETAILS:
          const trackingDetails = async () => {
            const promiseFunc = [
              ga4Event(ACTION_APP.PERSONAL_DETAIL_LOADED, undefined),
              _segmentTracking(SegmentAction.SQ_PERSONAL_DETAIL_LOADED, undefined),
              funcNoCallback(() => {
                gaEventApp(ACTION_APP.PERSONAL_DETAIL_LOADED, undefined)
              }),
            ]
            await Promise.all(promiseFunc)
          }
          await trackingDetails()
          break
        default:
          break
      }
    }
    handleSwitchPath(hardScreen ?? screen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen, recordResponse?.serviceable])

  return (
    <>
      <div className="quiz-question">{quizQuestionScreens[hardScreen ?? screen ?? ''] ?? <></>}</div>
      {/* only boolean */}
      {recordResponse?.serviceable === false && <Unserviceable />}
      <ImageCachePreloader imageUrls={imagesPreload} />
    </>
  )
}
