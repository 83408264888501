/* eslint-disable @typescript-eslint/no-explicit-any */
export enum SegmentAction {
  SQ_QUIZ_LOADED = 'SQ_QUIZ_LOADED',
  SQ_QUIZ_SUBMITTED = 'SQ_QUIZ_SUBMITTED',
  SQ_QUESTION_COMPLETED = 'SQ_QUESTION_COMPLETED',
  SQ_IMAGE_SWIPED = 'SQ_IMAGE_SWIPED',
  SQ_CONTACT_INFO = 'SQ_CONTACT_INFO',
  SQ_FULLY_QUALIFIED_LEAD = 'SQ_FULLY_QUALIFIED_LEAD',
  SQ_CALL_SCHEDULED = 'SQ_CALL_SCHEDULED',
  SQ_MESSAGE_SUBMITTED = 'SQ_MESSAGE_SUBMITTED',
  SQ_INITIAL_ENGAGEMENT = 'SQ_INITIAL_ENGAGEMENT',
  SQ_IMAGE_RESPONSE_SWIPE = 'SQ_IMAGE_RESPONSE_SWIPE',
  SQ_IMAGE_RESPONSE_TAP = 'SQ_IMAGE_RESPONSE_TAP',
  SQ_SCHEDULE_CALL_CLICKED = 'SQ_SCHEDULE_CALL_CLICKED',
  SQ_SEND_MESSAGE_CLICKED = 'SQ_SEND_MESSAGE_CLICKED',
  SQ_BOOK_NOW_CLICKED = 'SQ_BOOK_NOW_CLICKED',
  SQ_SHOWED_RETAKE_MODAL = 'SQ_SHOWED_RETAKE_MODAL',
  SQ_RETAKE_MODAL_SEND_A_MESSAGE_CLICKED = 'SQ_RETAKE_MODAL_SEND_A_MESSAGE_CLICKED',
  SQ_RESULTS_LOADED = 'SQ_RESULTS_LOADED',
  SQ_FLORAL_FOCUS = 'SQ_FLORAL_FOCUS',
  SQ_SEE_PROPOSAL_CLICKED = 'SQ_SEE_PROPOSAL_CLICKED',

  // Legacy distinction
  SQ_LEGACY_LOADED = 'Legacy Loaded',

  // Experiments
  SQ_DETAIL_VARIANT_1 = 'SQ_DETAIL_VARIANT_1',
  SQ_DETAIL_VARIANT_2 = 'SQ_DETAIL_VARIANT_2',
  SQ_DETAIL_VARIANT_3 = 'SQ_DETAIL_VARIANT_3',
  SQ_DETAIL_VARIANT_4 = 'SQ_DETAIL_VARIANT_4',
  SQ_DETAILx2_VARIANT_1 = 'SQ_DETAILx2_VARIANT_1',
  SQ_DETAILx2_VARIANT_2 = 'SQ_DETAILx2_VARIANT_2',
  SQ_DETAILx2_VARIANT_3 = 'SQ_DETAILx2_VARIANT_3',

  // Canny Valley
  SQ_CV_LOADED = 'Canny Valley Loaded',
  SQ_CV_DETAILS_COMPLETED = 'Canny Valley Details Completed',
  SQ_CV_SERVICEABLE = 'Canny Valley Serviceable',
  SQ_CV_COLOR_PALETTE_SELECTED = 'Canny Valley Color Palette Selection Completed',
  SQ_CV_COLOR_PALETTE_CUSTOM_COMPLETED = 'Canny Valley Color Palette Customization Completed',
  SQ_CV_INSPIRATION_UPLOADED = 'Canny Valley Inspiration Uploaded',
  SQ_CV_INSPIRATION_SELECTED = 'Canny Valley Inspiration Selected',
  SQ_CV_INSPIRATION_COMPLETED = 'Canny Valley Inspiration Completed',
  SQ_CV_STEMS_STARTED = 'Canny Valley Stems Started',
  SQ_CV_STEMS_COMPLETED = 'Canny Valley Stems Completed',
  SQ_CV_BUDGET_COMPLETED = 'Canny Valley Budget Completed',
  SQ_CV_SCHEDULE_CALL_STARTED = 'Canny Valley Schedule Call Started',
  SQ_CV_SCHEDULE_CALL_COMPLETED = 'Canny Valley Schedule Call Completed',
  CANNY_VALLEY_SHOP_FLOWERS_CLICKED = 'Canny Valley Shop Flowers Clicked',
  OPENED_FAQ_1 = 'opened_FAQ_1',
  CLOSED_FAQ_1 = 'closed_FAQ_1',
  OPENED_FAQ_2 = 'opened_FAQ_2',
  CLOSED_FAQ_2 = 'closed_FAQ_2',
  OPENED_FAQ_3 = 'opened_FAQ_3',
  CLOSED_FAQ_3 = 'closed_FAQ_3',

  // add path name for quiz
  SQ_DETAIL_LOADED = 'SQ_DETAIL_LOADED',
  SQ_PERSONAL_DETAIL_LOADED = 'SQ_PERSONAL_DETAIL_LOADED',
  SQ_WEDDING_DETAIL_LOADED = 'SQ_WEDDING_DETAIL_LOADED',
  SQ_BUDGET_LOADED = 'SQ_BUDGET_LOADED',
  SQ_COLOR_PALETTE_LOADED = 'SQ_COLOR_PALETTE_LOADED',
  SQ_AVAILABILITY_LOADED = 'SQ_AVAILABILITY_LOADED',
  SQ_UNAVAILABILITY_LOADED = 'SQ_UNAVAILABILITY_LOADED',
}

export enum SegmentCategory {
  STYLE_QUIZ = 'Style Quiz',
}

export enum SegmentPageName {
  QUIZ = 'Quiz',
  DETAILS = 'Details',
  PERSONAL_DETAIL = 'Personal Details',
  WEDDING_DETAIL = 'Wedding Details',
  AVAILABILITY_PAGE = 'Availability',
  BUDGET_PAGE = 'Budget',
  COLOR_PALETTE_PAGE = 'Color Flowers',
  EXISTING_PROPOSAL = 'Existing Proposal',
  QUIZ_END = 'End Quiz',
  STYLE_INFO = 'Style Info',
  YOUR_POPPY_PROPOSAL = 'Your Poppy Proposal',
  CALL_SCHEDULED = 'Call Scheduled',
  $500_CUSTOMER = '$500 Customer',
  SCHEDULE_YOUR_CONSULTATION = 'Schedule Your Consultation',
  MOODBOARD = 'Moodboard',
  POST_MOODBOARD = 'Post Moodboard',
  SCHEDULE_A_CALL = 'Schedule A Call',
  UNKNOWN = 'Unknown',
  MO_COLOR_PALETTE_PAGE = 'MO Color Palettes',
  MO_AVAILABILITY_PAGE = 'MO Availability',
  MO_DETAILS = 'MO Details',
  MO_PLANNING_STAGE = 'MO Planning Stage',
  MO_GUEST_COUNT = 'MO Guest Count',
  MO_BUDGET_PAGE = 'MO Budget',
  MO_ESSENTIALS_COLLECTION_PAGE = 'MO Essentials Collection',
  MO_CONTACT_INFO = 'MO Contact Info',
}

export const segmentTracking = (action: string, properties?: any, options?: any, callback?: (value: any) => void) => {
  const analytics = (window as { [key: string]: any }).analytics
  if (analytics) {
    analytics.track(action, properties, options, callback)
  }
}

export const segmentIdentify = (id: string, traits?: any, options?: any, callback?: (value: any) => void) => {
  const analytics = (window as { [key: string]: any }).analytics
  if (analytics) {
    analytics.identify(id, traits, options, callback)
  }
}

export const segmentPageTracking = (
  category: string,
  name: string,
  properties?: any,
  options?: any,
  callback?: (value: any) => void
) => {
  const analytics = (window as { [key: string]: any }).analytics
  if (analytics) {
    analytics.page(category, name, properties, options, callback)
  }
}
